import { get, put, post } from '../Services/http';
import { catchError } from './Error.action';
import { CART_LOAD_ERROR, CART_RECEIVE, CART_SHOW_INFO, CART_SET_ROW_COMMENT } from '../constants';

export const load = () => (dispatch, getState) => {
    return get('/api/cart')
        .then((response) => response.json())
        .then((cart) => dispatch(receive(cart)))
        .catch((ex) => dispatch(catchError(ex, (error) => loadError(error))));
};

export const loadError = (error) => ({
    type: CART_LOAD_ERROR,
    payload: {
        error,
    },
});

export const receive = (cart) => ({
    type: CART_RECEIVE,
    payload: cart,
});

export const toggle = () => (dispatch, getState) => {
    dispatch(show(!getState().cart.showInfo));
};

const show = (visible) => ({
    type: CART_SHOW_INFO,
    payload: {
        showInfo: visible,
    },
});

export const update = (articleNumber, quantity, abortController = null, rowSystemId = "00000000-0000-0000-0000-000000000000") => (
    dispatch
) => {
    return put(`/api/cart/update`, { articleNumber, quantity, rowSystemId }, abortController)
        .then((response) => response.json())
        .then((cart) => dispatch(receive(cart)))
        .catch((ex) => dispatch(catchError(ex, (error) => loadError(error))));
};

export const clearCart = () => (
    dispatch
) => {    
    return put(`/api/cart/clear`, {})
        .then((response) => {
            return response.json();
        })
        .then((cart) => {
            dispatch(receive(cart));
        })
        .catch((ex) => {
            dispatch(catchError(ex, (error) => {
                console.error('Handling load error:', error);
                return loadError(error);
            }));
        });
};

export const historyReorder = (orderId, emptyCart) => async (dispatch) => {

    try {
    const response = await post('/api/cart/historyReorder', { orderId, emptyCart });

    const cart = await response.json();

    dispatch(receive(cart));
  } catch (error) {
    dispatch(catchError(error, loadError));  
  }
};
export const quoteReorder = (orderId, emptyCart) => async (dispatch) => {

  try {
    const response = await post('/api/cart/QuoteReorder', { orderId, emptyCart });
    const contentType = response.headers.get("Content-Type");

    if (!response.ok) {
      const errorBody = await response.text();  // Read response as text to avoid JSON parse error
      console.error("Error response body:", errorBody);
      throw new Error(`Server error ${response.status}: ${errorBody}`);
    }

    // Check if the response is JSON before attempting to parse it
    let cart;
    if (contentType && contentType.includes("application/json")) {
      cart = await response.json();
    } else {
      const text = await response.text();  // Response is not JSON, read as text
      throw new Error("Received non-JSON response from the server.");
    }

    dispatch(receive(cart));
  } catch (error) {
    console.error("Failed to reorder:", error);
    dispatch(catchError(error, loadError));
  }
};





export const checkIfCartIsEmpty = () => async (dispatch) => {
    try {
        const response = await get('/api/cart');
        const cart = await response.json();
        return !cart.orderRows || cart.orderRows.length === 0;
    } catch (error) {
        dispatch(catchError(error));
        return false;
    }
};

export const updateOrderRowComment = (comment, rowSystemId) => (dispatch) => {
    return put(`/api/cart/updateOrderRowComment`, { comment, rowSystemId }, )
        .then((response) => response.json())
        .then((cart) => dispatch(receive(cart)))
        .catch((ex) => dispatch(catchError(ex, (error) => loadError(error))));
};

export const changeOrderRowCommentValue = (comment, rowSystemId) => ({
    type: CART_SET_ROW_COMMENT,
    payload: {
        key: rowSystemId,
        data: comment
    }
});